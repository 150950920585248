<!-- 报名申请 -->
<template>
  <div class="signApply">
    <div class="sign-form">
      <div class="sign">报名申请</div>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        id="form-1"
        class="form"
        inline
        :label-width="label_width"
      >
        <el-form-item label="考生姓名" prop="name">
          <el-input
            v-model="form.name"
            placeholder="请输入考生姓名"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input
            v-model="form.telephone"
            placeholder="请输入联系电话"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="机构名称">
          <el-input
            v-model="form.mechanism.mechanism"
            placeholder=""
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="指导教师">
          <el-input v-model="form.teacher" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="学科" prop="subject">
          <el-select
            v-model="form.subject"
            filterable
            placeholder="请输入并选择学科"
            :clearable="true"
            @change="subjectChange"
          >
            <el-option
              v-for="item in form.subjectOpt"
              :key="item.id"
              :label="item.course_name"
              :value="item.course_name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原等级" prop="grade">
          <el-select
            v-model="form.grade"
            placeholder="请选择原等级"
            :clearable="true"
            @change="gradeChange"
          >
            <el-option
              v-for="item in form.gradeOpt"
              :key="item.id"
              :label="item.abbreviation"
              :value="item.level"
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考等级" prop="applyRank">
          <el-select
            v-model="form.applyRank"
            placeholder="请选择报考等级"
            :clearable="true"
            @change="applyRankChange"
          >
            <el-option
              v-for="item in form.gradeOpt"
              :key="item.id"
              :label="item.abbreviation"
              :value="item.level"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考级报名费">
          <el-input v-model="form.regFee" placeholder="" disabled></el-input>
        </el-form-item>
        <el-form-item label="副证费">
          <el-input
            v-model="form.certificatecopy_price"
            placeholder="30"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="考试场次">
          <el-input
            v-model="form.sessions.exam_name"
            placeholder=""
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="应缴考试费">
          <el-input v-model="form.payable" placeholder="" disabled></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            :rows="4"
            type="textarea"
            v-model="form.remarks"
            class="textArea-width"
            placeholder="请输入"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <!-- 底部 提交与取消 -->
      <div slot="footer" class="dialog-footer">
        <el-button
          style="background-color: #18bc9c; color: #ffffff"
          @click="submitForm('form')"
          >提交报名申请
        </el-button>
        <!-- <el-button class="btn" @click="resetForm('form')">取 消</el-button> -->
      </div>
    </div>

    <el-divider></el-divider>
    <div class="buttom">
      <div class="">
        &copy2015 深圳书画学院 考生考级报名系统 All rights reserved
      </div>
      <div class="">服务热线：0755-8883 0206</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "", //考生姓名
        telephone: "", //联系电话
        mechanism: {
          mechanism: "书画学院",
          mechanism_id: "1",
        }, //机构名称
        teacher: "", //指导教师
        subject: "", //学科
        subject_id: "", //学科id
        subjectOpt: [], //学科选项
        grade: null, //原等级
        gradeId: 0,
        gradeOpt: [], //等级选项
        applyRank: null, //报考等级
        applyRankId: 0,
        regFee: "", //考级报名费
        payable: "", //应缴考试费
        remarks: "", //备注
        sessions: {
          exam_name: "",
          id: "",
        }, //考试场次
        idcard: "", //身份证
        birthday: "", //出生日期
      },
      label_width: "150px",
      rules: {
        name: [
          {
            required: true,
            message: "考生姓名不能为空",
            trigger: "blur",
          },
        ],
        subject: [
          {
            required: true,
            message: "学科不能为空",
            trigger: "change",
          },
        ],
        applyRank: [
          {
            required: true,
            message: "报考等级不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    // 监听屏幕变化改变布尔值
    console.log(document.body.clientWidth);
    if (document.body.clientWidth <= 768) {
      this.label_width = "100px";
    }
    window.onresize = () => {
      // console.log(document.documentElement.clientWidth)
      if (document.documentElement.clientWidth <= 768) {
        this.label_width = "100px";
      }
    };
    // console.log(this.label_width);
  },
  created() {
    // 学科列表
    this.$request({
      url: "/api/studentexam/courseList",
      method: "POST",
      data: {
        uid: localStorage.getItem("token"),
      },
    }).then((res) => {
      // console.log(res,'学科列表')
      this.form.subjectOpt = res.data;
    });

    // 等级选项
    this.$request({
      url: "/api/studentexam/levelList",
      method: "POST",
      data: {
        uid: localStorage.getItem("token"),
      },
    }).then((res) => {
      if (res.code == 1) {
        this.form.gradeOpt = res.data;
      }
    });

    // 考试场次
    this.$request({
      url: "/api/studentexam/examcategory",
      method: "POST",
      data: {
        uid: localStorage.getItem("token"),
      },
    }).then((res) => {
      console.log(res);
      if (res.code == 1) {
        this.form.sessions = res.data;
      } else {
        this.$alert(res.msg, "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            this.$message({
              type: "warning",
              message: res.msg,
            });
            this.$router.push({
              path: "/dashboard",
            });
          },
        });
      }
    });

    //机构名称
    this.$request({
      url: "/api/studentexam/info",
      method: "POST",
      data: {
        uid: localStorage.getItem("token"),
      },
    }).then((res) => {
      console.log(res, "机构名称");
      if (res.code == 1) {
        this.form.telephone = res.data.mobile1;
        this.form.name = res.data.username;
        this.form.mechanism.mechanism = res.data.mechanism;
        this.form.mechanism.mechanism_id = res.data.mechanism_id;
        // console.log(res,11)
        this.form.idcard = res.data.idcard;
        this.form.birthday = res.data.birthdate;
      }
    });
  },
  methods: {
    submitForm(form) {
      //提交
      var table = this.form;
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$request({
            url: "/api/studentexam/signup",
            method: "POST",
            data: {
              uid: localStorage.getItem("token"),
              name: table.name, //学生姓名
              phone: table.telephone, //联系方式
              teacher: table.teacher, //授课老师
              course_id: table.subject_id, //学科ID
              original_level: table.grade, //原等级值
              original_level_id: table.gradeId, //原等级id
              exam_level: table.applyRank, //现等级值
              level_id: table.applyRankId, //现等级id
              examcategory_id: table.sessions.id, //考试场次ID
              org_name: table.mechanism.mechanism, //机构名称
              org_id: table.mechanism.mechanism_id, //机构ID
              enroll_price: table.regFee, //考级报名费
              certificatecopy_price: 30, //副证费
              price1: table.payable, //应缴考试费
              remark: table.remarks, //备注
              idcard: table.idcard, //身份证
              course: table.subject, //科目
              birthday: table.birthday, //出生日期
            },
          }).then((res) => {
            console.log(res);
            if (res.code == 1) {
              this.$message({
                message: "申请成功",
                type: "success",
              });
              this.$router.push({
                path: "/signRecord",
              });
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 学科选择
    subjectChange(e) {
      // this.form.subject = e
      let result = this.form.subjectOpt.find((item) => item.course_name == e);
      this.form.subject_id = result.id;
      // console.log(this.form.subject_id, this.form.subject)
      this.$request({
        url: "/api/studentexam/courseLevel",
        method: "POST",
        data: {
          uid: localStorage.getItem("token"),
          idcard: this.form.idcard,
          course_id: this.form.subject_id,
        },
      }).then((res) => {
        // console.log(res,'学科等级')
        if (res.code == 1) {
          this.form.grade = res.data || null;
        }
      });
    },
    gradeChange(e) {
      if (e == "") {
        this.form.applyRank = null;
        this.form.grade = null;
        this.form.regFee = "";
        return;
      }
      if (this.form.applyRank <= e && this.form.applyRank != null) {
        this.$message({
          message: "原等级必须低于报考等级",
          type: "warning",
        });
        this.form.grade = null;
        return;
      }
      var leval = 4;
      if (this.form.applyRank > leval && this.form.applyRank - e > 1) {
        this.$message({
          message: "原等级与报考等级相差不可超过1级",
          type: "warning",
        });
        this.form.grade = null;
        return;
      }
      this.form.gradeOpt.forEach((item) => {
        if (item.level == e) {
          this.form.gradeId = item.id;
        }
      });
      this.form.grade = e;
    },
    applyRankChange(e) {
      if (e == "") {
        this.form.applyRank = null;
        this.form.grade = null;
        this.form.regFee = "";
        return;
      }
      if (this.form.grade >= e) {
        this.$message({
          message: "原等级必须低于报考等级",
          type: "warning",
        });
        this.form.applyRank = null;
        this.form.regFee = "";
        return;
      }
      var leval = 4;
      if (e > leval && !this.form.grade) {
        this.$message({
          message: "原等级不能为空",
          type: "warning",
        });
        this.form.applyRank = null;
        this.form.regFee = "";
        return;
      }
      if (e > leval && e - this.form.grade > 1) {
        this.$message({
          message: "原等级与报考等级相差不可超过1级",
          type: "warning",
        });
        this.form.applyRank = null;
        this.form.regFee = "";
        return;
      }
      this.form.gradeOpt.forEach((item) => {
        if (item.level == e) {
          this.form.applyRankId = item.id;
          this.form.regFee = item.price;
          this.form.payable = Number(item.price) + 30 + ".00";
        }
      });
      this.form.applyRank = e;
    },
    resetForm(form) {
      //取消
      this.$refs[form].resetFields();
    },
  },
};
</script>

<style scoped="scoped">
.signApply {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sign-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sign {
  padding: 25px;
  font-size: 16px;
  font-weight: bold;
  box-sizing: border-box;
}

/* 表单部分 */
.el-form {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.form ::v-deep .el-form-item__label {
  color: #000000;
}

.el-input {
  width: 300px;
  height: 37px !important;
}

.el-select {
  width: 300px;
}

.form ::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #fff;
  color: #333;
}

/* 底部提交 取消 */
.dialog-footer {
  text-align: center;
  margin-top: 30px;
}

.btn:hover {
  color: #666;
  border-color: #ccc;
  background-color: #ffffff;
}

/* 底部 */
.buttom {
  height: 30px;
  line-height: 30px;
  padding: 0px 25px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  ::v-deep .el-input__inner {
    width: 160px !important;
    height: 30px !important;
    line-height: 30px !important;
  }
  ::v-deep .main {
    min-width: auto;
    height: 100vh;
  }
  .el-form {
    display: flex !important;
    flex-direction: column !important;
  }
  ::v-deep .el-input {
    width: 160px !important;
    height: 30px !important;
    line-height: 30px !important;
  }

  ::v-deep .el-select {
    width: 160px !important;
    height: 30px !important;
    line-height: 30px !important;
  }

  ::v-deep .el-form-item__label {
    text-align: center !important;
  }

  ::v-deep .el-input__icon {
    line-height: 30px !important;
  }

  ::v-deep .el-main {
    margin: 10px !important;
  }

  ::v-deep .textArea-width {
    width: 160px !important;
  }

  ::v-deep .buttom {
    display: none !important;
  }

  ::v-deep .dialog-footer {
    margin-bottom: 20px !important;
  }
}
</style>
